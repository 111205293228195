import { initilRasaEnv } from "@/api/bootsrap";
import { isEmpty } from "lodash";

const DEFAULT_TIMEOUT = 10 * 1000;
export default class InitListen {
  interval: any | null;
  timeout: number
  constructor(timeout = DEFAULT_TIMEOUT) {
    this.timeout = timeout;
  }
  start(): void {
    this.fetchApi();
  }
  private fetchApi() {
    initilRasaEnv().then(res => {
      if (isEmpty(res)) {
        requestIdleCallback(() => this.listen());
      } else {
        this.interval && clearTimeout(this.interval);
        setTimeout(() => window.location.replace('/'))
      }
    })
  }
  listen() {
    this.interval = setTimeout(() => {
      this.fetchApi();
    }, this.timeout);
  }
  done(): void {
    if (this.interval) {
      clearTimeout(this.interval);
    }
  }
}