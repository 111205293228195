import useLocale, { useLocaleLang } from '@/utils/useLocale';
import { Button, Carousel, Typography } from '@arco-design/web-react';
import React, { useLayoutEffect, useRef } from 'react';
import EntryLayout from '../components/entry-layout';
import config from './config';
import InitListen from './InitListen';
import i18n from './locale';

const Introduce = () => {
  const lang = useLocaleLang();
  const t = useLocale(i18n);
  const ref = useRef<InitListen>();
  useLayoutEffect(() => {
    const listener = new InitListen();
    listener.start();
    ref.current = listener;
    return () => {
      listener.done();
    };
  }, []);
  const onContinue = () => {
    ref.current.done();
    setTimeout(() => window.location.replace('/'));
  };
  return (
    <EntryLayout custom backend>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Carousel
          style={{ width: '90%', height: '60%' }}
          autoPlay={true}
          indicatorType="dot"
          showArrow="hover"
          animation="card"
        >
          {config.image[lang.split('-')[0]].map(({ key, src, title }) => (
            <div key={key} style={{ width: '60%' }}>
              <div
                style={{
                  position: 'relative',
                  left: 0,
                  top: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 14,
                  paddingRight: 14,
                }}
              >
                <Typography.Paragraph style={{ color: 'white', fontSize: 16 }}>
                  {title}
                </Typography.Paragraph>
              </div>
              <img src={src} style={{ width: '100%' }} />
            </div>
          ))}
        </Carousel>
        <Typography.Title
          heading={5}
          style={{
            color: 'white',
            marginTop: 16,
            maxWidth: '80%',
            textAlign: 'center',
          }}
        >
          {t['ready.proccess']}
        </Typography.Title>
        <Button size="large" type="primary" onClick={onContinue}>
          {t['continue.back']}
        </Button>
      </div>
    </EntryLayout>
  );
};

export default Introduce;
