const i18n = {
  'en-US': {
    'request.400': 'Api params error',
    'request.network-error': 'Network Error',
    'request.401-403.title': 'Please Login',
    'request.401-403.content': 'Authorization info is timeout',
    'request.unknown-error': 'Unknown Error',
    'menu.licenses': 'License',
    'menu.statistics': 'Statistics',
    'menu.projects.id.overview': 'Overview',
    'menu.projects.id.llm': 'LLMs',
    'menu.projects.id.sample': 'FAQ',
    'menu.projects.id.knowledge': 'Knowledge Base',
    'menu.projects.id.knowledge.text': 'Text',
    'menu.projects.id.knowledge.web': 'Web',
    'menu.projects.id.knowledge.pdf': 'File',
    'menu.dashboard': 'Dashboard',
    'menu.dashboard.workplace': 'Workplace',
    'menu.user.info': 'User Info',
    'menu.user.setting': 'User Setting',
    'menu.user.switchRoles': 'Switch Roles',
    'menu.user.role.admin': 'Admin',
    'menu.user.role.user': 'General User',
    'menu.example': 'Example',
    'menu.demo': 'Demo',
    'menu.demo.faqs': 'Faqs',
    'menu.demo.faqs.id': 'Faq',
    'menu.demo.forms': 'Forms',
    'menu.templates': 'Templates',
    'menu.projects': 'My Projects',
    'menu.projects.id': 'Project',
    'menu.projects.id.dashboard': 'Dashboard',
    'menu.projects.id.entities': 'Entities',
    'menu.projects.id.slots': 'Slots',
    'menu.projects.id.synonyms': 'Synonyms',
    'menu.projects.flow': 'Dialogue process',
    'menu.projects.id.view': 'Project View',
    'menu.projects.id.tool': 'Project Tool',
    'menu.projects.id.intents': 'Intents',
    'menu.projects.id.bots': 'Responses',
    'menu.projects.id.faqs': 'FAQ',
    'menu.projects.id.conversations': 'Flows',
    'menu.projects.id.stories': 'Stories',
    'menu.projects.id.messages': 'History',
    'menu.projects.id.webhooks': 'Webhooks',
    'menu.projects.id.webhooks.create': 'Create Webhook',
    'menu.projects.id.action': 'Action',
    'menu.projects.id.manage': 'Release',
    'menu.projects.id.setting': 'Settings',
    'menu.projects.id.run': 'Run',
    'menu.projects.id.forms': 'Forms',
    'menu.libs': 'Project Templates',
    'menu.settings': 'Settings',
    'menu.settings.users': 'Users',
    'menu.projects.id.complex': 'Flows',
    'menu.projects.id.branch': 'Flows',
    'menu.projects.id.complex.add': 'Add Flow',
    'menu.projects.id.complex.add.title': 'Add Flow',
    'menu.projects.id.complex.add.form.name': 'Name',
    'menu.projects.id.complex.add.form.name.placeholder':
      'Please enter the flow name',
    'menu.projects.id.complex.add.form.description': 'Description',
    'menu.projects.id.complex.add.form.description.placeholder': 'Description',
    'menu.projects.id.cycle': 'Timed Task',
    'navbar.logout': 'Logout',
    'settings.title': 'Settings',
    'settings.themeColor': 'Theme Color',
    'settings.content': 'Content Setting',
    'settings.navbar': 'Navbar',
    'settings.menuWidth': 'Menu Width (px)',
    'settings.navbar.theme.toLight': 'Click to use light mode',
    'settings.navbar.theme.toDark': 'Click to use dark mode',
    'settings.menu': 'Menu',
    'settings.footer': 'Footer',
    'settings.otherSettings': 'Other Settings',
    'settings.colorWeek': 'Color Week',
    'settings.alertContent':
      'After the configuration is only temporarily effective, if you want to really affect the project, click the "Copy Settings" button below and replace the configuration in settings.json.',
    'settings.copySettings': 'Copy Settings',
    'settings.copySettings.message':
      'Copy succeeded, please paste to file src/settings.json.',
    'settings.close': 'Close',
    'settings.color.tooltip':
      '10 gradient colors generated according to the theme color',
    'message.tab.title.message': 'Message',
    'message.tab.title.notice': 'Notice',
    'message.tab.title.todo': 'ToDo',
    'message.allRead': 'All Read',
    'message.seeMore': 'SeeMore',
    'message.empty': 'Empty',
    'message.empty.tips': 'No Content',
    'message.lang.tips': 'Language switch to ',
    'message.create.success': 'Create Success',
    'message.update.success': 'Update Success',
    'message.delete.success': 'Delete Success',
    'message.release.success': 'Release Success',
    'navbar.search.placeholder': 'Please search',
    'message.error': 'Error',
    'message.suspended.success': 'Success',
    'layout.project.trian': 'Train',
    'layout.project.test': 'Test',
    'layout.project.run': 'Run',
    'layout.project.settings': 'Settings',
    'rules.required': 'Required Field',
    'rules.required.selector': 'Required Select',
    'rules.mobile': 'Required Mobile',
    'rules.email': 'Required Email',
    'command.zoomIn': 'ZoomIn',
    'command.zoomOut': 'ZoomOut',
    'command.download': 'Download',
    'command.sub': 'Add Sub',
    'command.sister': 'Add Sister',
    'command.test': 'Auto Test',
    'command.run': 'Run',
    'command.trian.run': 'Debug',
    'command.trian.running': 'Debugging',
    'command.delete': 'Delete',
    'command.delete.content': 'Are you sure delete this Node and children ?',
    'command.full-screen': 'Full Screen',
    'command.quit-full-screen': 'Quit Full Screen',
    'command.form': 'Add Form',
    'command.save': 'Save',
    'command.goto': 'Goto',
    'common.node.user': 'User',
    'common.node.bot': 'Bot',
    'common.node.webhook': 'Webhook',
    'common.node.selector': 'Selector Node Type',
    'common.delete.confirm.title': 'Delete data',
    'common.delete.confirm.content': 'Are you sure delete this ?',
    'common.import': 'Import KB Data',
    'common.import.success': 'Import data success',
    'common.import.error': 'Import data error',
    'command.enlarge': 'Enlarge',
    'command.narrow': 'Narrow',
    'common.download.template': 'Download KB Template',
    'common.empty': 'Empty Data',
    'common.upload.error': 'Upload Error',
    'common.upload.success': 'Upload Success',
    'page.load.loading': 'Loading',
    'sysadmin.title': 'Sysadmin',
    'sysadmin.create.form': 'Create Account',
    'sysadmin.edit.form': 'Edit Account',
    'sysadmin.create.note.form': 'Add New Note',
    'sysadmin.form.note': 'Note',
    'sysadmin.form.timezone': 'Timezone',
    'sysadmin.form.name': 'Name',
    'sysadmin.form.Owner': 'Owner',
    'sysadmin.form.admins': 'Admins',
    'sysadmin.form.admins.placeholder':
      'Please enter the administrator account. Please use English comma "," to distinguish multiple accounts!',
    'sysadmin.form.accountType': 'Type',
    'sysadmin.form.featureEnable': 'Feature',
    'sysadmin.form.active': 'Active',
    'sysadmin.form.actived': 'Activing',
    'sysadmin.form.unactived': 'Unactived',
    'sysadmin.form.state': 'state',
    'sysadmin.form.accountType.normal': 'Normal Account',
    'sysadmin.form.accountType.trial': ' Trial Account',
    'sysadmin.form.fullname': 'Corporate name',
    'sysadmin.table.name': 'Name',
    'sysadmin.table.state': ' State',
    'sysadmin.table.admin': ' owner',
    'sysadmin.table.timezone': 'Timezone',
    'sysadmin.table.baseInfo': 'Essential Information',
    'sysadmin.table.state.ready': 'Ready',
    'sysadmin.table.state.suspended': 'Suspended',
    'sysadmin.table.state.suspended.before': 'Are you sure you want to pause',
    'sysadmin.table.state.ready.before': 'Are you sure you want to open',
    'sysadmin.table.state.suspended.after': 'user usage service',
    'sysadmin.table.state.delete': 'Delete',
    'sysadmin.table.state.visit': 'Visit',
    'sysadmin.admin.error':
      'Please enter the correct mobile phone number or email!',

    'sysadmin.table.state.delete.title':
      'Are you sure you want to delete the user',
    'sysadmin.table.state.terminated': ' Terminated',
    'sysadmin.table.state.settingUp1': ' settingUp1',
    'sysadmin.table.type': 'Type',
    'sysadmin.table.events': 'Events',
    'sysadmin.table.eventsNot': 'No event',
    'sysadmin.table.notesNot': 'No notes',

    'sysadmin.table.notes': 'Notes',
    'sysadmin.table.operation': 'Operationss',
    'sysadmin.table.operation.edit': 'Edit',
    'sysadmin.table.operation.note': 'Note',
    'sysadmin.table.operation.delete': 'Delete',
    'sysadmin.table.operation.delete.placeholder':
      'Are you sure you want to delete?',
    'sysadmin.from.admin': 'Please enter mobile phone number or email',

    'from.input.placeholder': 'Please enter',
    'from.select.placeholder': 'Please select',
    'from.submit.text': 'Submit',
    'from.reset.text': 'Reset',
    'globalLayout.register.placeholder':
      'For security reasons, please update your password',
    delete: 'Delete',
    link: 'Enter',

    'publish.update.time': 'Released at',
    'image.tooltip.add': 'Add picture',
    'image.tooltip.delete': 'Delete picture',
    'image.crop': 'Crop Image',
    'image.tooltip.preview': 'Preview picture',
    'image.tooltip.select': 'Select picture',
    edit: 'Edit',
    InformationList: 'Information list',
    ControlQandA: 'Control Q & A',
    CollectionCompleted: 'Complete',
    confirm: 'Confirm',
    'validator.faq.faq-root.empty': 'Please check the dialogue process',
    'validator.faq.faq-root.intent':
      'Dialogue process requires at least one subsequent node',
    'validator.faq.user.empty': 'Please check User',
    'validator.faq.user.botEmpty':
      'User needs to correspond to at least one intelligent reply',
    'validator.faq.botEmpty': 'Please check the Bot Response',
    'validator.conversation.empty': 'Please check the dialogue process',
    'validator.conversation.intent.empty':
      'Dialogue process requires at least one subsequent node',
    'validator.conversation.user.empty': 'Please check User',
    'validator.conversation.user.botEmpty':
      'User needs to correspond to at least one intelligent reply or form',
    'validator.conversation.option.empty': 'Please check user options',
    'validator.conversation.option.botEmpty':
      'User needs to correspond to at least one intelligent reply or form',
    'validator.conversation.bot.empty': 'Please check the Bot Response',
    'validator.conversation.form.empty':
      'Please check the information collection',
    'validator.conversation.slots.empty':
      'Information list requires at least one information',
    'validator.conversation.field.empty':
      'Information collection requires a complete definition',
    'validator.conversation.rhetorical.empty':
      'Information collection is missing. Inquiry needs to be fully defined',
    'validator.conversation.rhetorical.userEmpty':
      'After information collection missing query, at least one User needs to be defined',
    'validator.conversation.confirm.botEmpty':
      'After collection, confirm that at least one intelligent reply is required',
    'validator.conversation.breakEmpty': 'Please check the jump out form',
    'validator.conversation.conditionEmpty':
      'Please check the jump out form anchor',
    'validator.conversation.condition.botEmpty': 'Jump out of form anchor',
    add: 'Add',

    'train.state.prepare': ' Preparation',
    'train.state.trainModel': ' Training',
    'train.state.publish': ' deployment',
    'train.cancel': 'Cancel',
    'component.intent': 'Unknown input',
    running: 'In training',
    'footer.copyright': 'Prompt AI',
    'header.joinDiscuss': 'Join discussion group',
    'header.document': 'Document',
    'graphnext.interrupt': 'collection interrupt',
    'graphnext.undefined': 'Undefined',
    'menu.projects.id.favorites': 'Favorites',
    'menu.projects.id.favorites.faqs': 'Faqs',
    'menu.projects.id.favorites.flows': 'Flows',
    purchase: 'Purchase',
  },
  'zh-CN': {
    'component.intent': '未知输入',

    'train.state.prepare': '数据准备',
    'train.state.trainModel': '模型训练',
    'train.state.publish': '模型部署',
    'train.cancel': '取消',

    'validator.faq.faq-root.empty': '请检查对话流程',
    'validator.faq.faq-root.intent': '对话流程至少需要一个后续节点',
    'validator.faq.user.empty': '请检查用户输入',
    'validator.faq.user.botEmpty': '用户输入需要对应至少一个机器回复',
    'validator.faq.botEmpty': '请检查机器回复',
    'validator.conversation.empty': '请检查对话流程',
    'validator.conversation.intent.empty': '对话流程至少需要一个后续节点',
    'validator.conversation.user.empty': '请检查用户输入',
    'validator.conversation.user.botEmpty':
      '用户输入需要对应至少一个机器回复或者表单',
    'validator.conversation.option.empty': '请检查用户选项',
    'validator.conversation.option.botEmpty':
      '用户输入需要对应至少一个机器回复或者表单',
    'validator.conversation.bot.empty': '请检查机器回复',
    'validator.conversation.form.empty': '请检查信息收集',
    'validator.conversation.slots.empty': '信息列表至少需要一个信息',
    'validator.conversation.field.empty': '信息收集需要完整定义',
    'validator.conversation.rhetorical.empty': '信息收集缺失询问需要完整定义',
    'validator.conversation.rhetorical.userEmpty':
      '信息收集缺失询问后，至少需要定义一个用户输入',
    'validator.conversation.confirm.botEmpty':
      '表单结束后确认至少需要一个机器回复',
    'validator.conversation.breakEmpty': '请检查跳出表单',
    'validator.conversation.conditionEmpty': '请检查跳出表单锚点',
    'validator.conversation.condition.botEmpty': '跳出表单锚点',
    add: '增加',
    confirm: '确认',
    CollectionCompleted: '表单结束',
    InformationList: '信息列表',
    ControlQandA: '控制问答',
    'image.tooltip.add': '添加图片',
    'image.tooltip.select': '选择图片',
    'image.tooltip.delete': '删除图片',
    'image.crop': '剪裁图片',
    'image.tooltip.preview': '预览图片',
    delete: '删除',
    edit: '编辑',
    'publish.update.time': '发布更新时间',
    'request.400': '参数错误',
    'request.network-error': '网络超时',
    'request.401-403.title': '请重新登录',
    'request.401-403.content': '认证信息过期',
    'request.unknown-error': '未知错误',
    'menu.licenses': 'Licenese',
    'menu.statistics': '报表统计',
    'menu.projects.id.overview': '对话概览',
    'menu.projects.id.llm': '大语言模型',
    'menu.projects.id.sample': '用户问答(FAQ)',
    'menu.projects.id.knowledge': '知识库',
    'menu.projects.id.knowledge.text': '文本库',
    'menu.projects.id.knowledge.web': '网络库',
    'menu.projects.id.knowledge.pdf': '文件库',
    'menu.dashboard': '仪表盘',
    'menu.dashboard.workplace': '工作台',
    'menu.user.info': '用户信息',
    'menu.user.setting': '用户设置',
    'menu.user.switchRoles': '切换角色',
    'menu.user.role.admin': '管理员',
    'menu.user.role.user': '普通用户',
    'menu.example': '例子',
    'menu.demo': 'Demo',
    'menu.demo.faqs': '用户问答(FAQ)',
    'menu.demo.faqs.id': '问答',
    'menu.demo.forms': '复杂问答',
    'menu.templates': 'Templates',
    'menu.projects': '我的项目',
    'menu.projects.id': '项目',
    'menu.projects.id.dashboard': '看板',
    'menu.projects.id.entities': '实体',
    'menu.projects.id.slots': '变量列表',
    'menu.projects.id.synonyms': '同义词',
    'menu.projects.flow': '对话流程',
    'menu.projects.id.view': '项目视图',
    'menu.projects.id.tool': '项目工具',
    'menu.projects.id.intents': '意图列表',
    'menu.projects.id.bots': '回答列表',
    'menu.projects.id.faqs': '用户问答(FAQ)',
    'menu.projects.id.conversations': '对话流图',
    'menu.projects.id.stories': '故事',
    'menu.projects.id.messages': '消息历史',
    'menu.projects.id.forms': '表单',
    'menu.projects.id.webhooks': 'Webhooks',
    'menu.projects.id.webhooks.create': '创建 Webhook',
    'menu.projects.id.action': 'Action',
    'menu.projects.id.manage': '发布运行',
    'menu.projects.id.setting': '项目设置',
    'menu.projects.id.run': '运行',
    'menu.libs': '项目模版',
    'menu.settings': '设置',
    'menu.settings.users': '用户管理',
    'menu.projects.id.complex': '对话流图',
    'menu.projects.id.branch': '对话流图',
    'menu.projects.id.complex.add': '新增流图',
    'menu.projects.id.complex.add.title': '新增流图',
    'menu.projects.id.complex.add.form.name': '名称',
    'menu.projects.id.complex.add.form.name.placeholder': '请输入名称',
    'menu.projects.id.complex.add.form.description': '描述',
    'menu.projects.id.complex.add.form.description.placeholder': '描述',
    'menu.projects.id.cycle': '定时任务',
    'navbar.logout': '退出登录',
    'settings.title': '页面配置',
    'settings.themeColor': '主题色',
    'settings.content': '内容区域',
    'settings.navbar': '导航栏',
    'settings.menuWidth': '菜单宽度 (px)',
    'settings.navbar.theme.toLight': '点击切换为亮色模式',
    'settings.navbar.theme.toDark': '点击切换为暗黑模式',
    'settings.menu': '菜单栏',
    'settings.footer': '底部',
    'settings.otherSettings': '其他设置',
    'settings.colorWeek': '色弱模式',
    'settings.alertContent':
      '配置之后仅是临时生效，要想真正作用于项目，点击下方的 "复制配置" 按钮，将配置替换到 settings.json 中即可。',
    'settings.copySettings': '复制配置',
    'settings.copySettings.message':
      '复制成功，请粘贴到 src/settings.json 文件中',
    'settings.close': '关闭',
    'settings.color.tooltip':
      '根据主题颜色生成的 10 个梯度色（将配置复制到项目中，主题色才能对亮色 / 暗黑模式同时生效）',
    'message.tab.title.message': '消息',
    'message.tab.title.notice': '通知',
    'message.tab.title.todo': '待办',
    'message.allRead': '全部已读',
    'message.seeMore': '查看更多',
    'message.empty': '清空',
    'message.empty.tips': '暂无内容',
    'message.lang.tips': '语言切换至 ',
    'message.create.success': '创建成功',
    'message.update.success': '修改成功',
    'message.delete.success': '删除成功',
    'message.release.success': '发布成功',
    'message.error': '失败',
    'navbar.search.placeholder': '输入内容查询',
    'message.suspended.success': '操作成功',
    'layout.project.trian': '训练',
    'layout.project.test': '测试',
    'layout.project.run': '运行',
    'layout.project.settings': '设置',
    'rules.required': '必填项',
    'rules.required.selector': '必选项',
    'rules.mobile': '手机号码格式错误',
    'rules.email': '邮箱格式错误',
    'command.zoomIn': '放大',
    'command.zoomOut': '缩小',
    'command.download': '下载',
    'command.sub': '新增子节点',
    'command.sister': '新增兄弟节点',
    'command.test': '自动测试',
    'command.run': '运行',
    'command.trian.run': '调试',
    'command.trian.running': '调试中',
    'command.delete': '删除',
    'command.delete.content': '确认删除这个节点及其子节点吗?',
    'command.full-screen': '全屏',
    'command.quit-full-screen': '退出全屏',
    'command.enlarge': '放大',
    'command.narrow': '缩小',
    'command.form': '新增表单',
    'command.save': '保存',
    'command.goto': '跳转',
    'common.node.user': '问题',
    'common.node.bot': '回答',
    'common.node.webhook': 'Webhook',
    'common.node.selector': '选择节点类型',
    'common.delete.confirm.title': '删除数据',
    'common.delete.confirm.content': '你确定删除',
    'common.import': '导入数据',
    'common.import.success': '导入成功',
    'common.import.error': '导入失败',
    'common.download.template': '下载模版',
    'common.empty': '暂无数据',
    'common.upload.error': '上传失败',
    'common.upload.success': '上传成功',
    'page.load.loading': '加载中',
    'sysadmin.title': '系统管理',
    'sysadmin.create.form': '创建账户',
    'sysadmin.edit.form': '编辑账户',
    'sysadmin.create.note.form': '添加备注',
    'sysadmin.form.timezone': '时区',
    'sysadmin.form.note': '备注',
    'sysadmin.form.name': '账户名',
    'sysadmin.form.Owner': '所有者',
    'sysadmin.form.admins': '管理员',
    'sysadmin.form.admins.placeholder':
      '请输入管理员账户，多个请用英语逗号 "," 区分！',
    'sysadmin.form.accountType': '账户类型',
    'sysadmin.form.featureEnable': '是否启用新功能',
    'sysadmin.form.active': '活跃',
    'sysadmin.form.actived': '活跃',
    'sysadmin.form.unactived': '不活跃',
    'sysadmin.form.accountReady': '状态',
    'sysadmin.form.accountType.normal': '正常账户',
    'sysadmin.form.accountType.trial': '测试账户',
    'sysadmin.form.fullname': '公司名称',

    'sysadmin.form.state': '状态',
    'sysadmin.table.name': '账户名',
    'sysadmin.table.state': ' 状态',
    'sysadmin.table.admin': '拥有者',
    'sysadmin.table.timezone': '时区',
    'sysadmin.table.state.ready': '开启',
    'sysadmin.table.state.suspended': '暂停',
    'sysadmin.table.state.suspended.before': '确定要暂停',
    'sysadmin.table.state.ready.before': '确定要开启',
    'sysadmin.table.state.suspended.after': '用户使用服务',
    'sysadmin.table.state.delete': '删除',
    'sysadmin.table.state.visit': '访问',
    'sysadmin.admin.error': '请输入正确的手机号或邮箱！',
    'sysadmin.table.state.delete.title': '确定要删除用户',
    'sysadmin.table.state.terminated': '结束',
    'sysadmin.table.state.settingUp1': ' 设置1',
    'sysadmin.table.type': '账户类型',
    'sysadmin.table.baseInfo': '基本信息',
    'sysadmin.table.eventsNot': '暂无事件',
    'sysadmin.table.events': '事件',
    'sysadmin.table.notesNot': '暂无备注',
    'sysadmin.table.notes': '备注',
    'sysadmin.table.operation': '操作',
    'sysadmin.table.operation.edit': '编辑',
    'sysadmin.table.operation.note': '备注',
    'sysadmin.table.operation.delete': '删除',
    'sysadmin.table.operation.delete.placeholder': '确定要删除吗',

    'sysadmin.from.admin': '请输入手机号或邮箱',
    'from.input.placeholder': '请输入',
    'from.select.placeholder': '请选择',
    'from.submit.text': '提交',
    'from.reset.text': '重置',
    'globalLayout.register.placeholder': '为了安全考虑，请您更新密码。',
    'globalLayout.register.okText': '现在就去',
    link: '进入',
    running: '训练中',
    'footer.copyright': 'Prompt AI',
    'header.joinDiscuss': '加入讨论群',
    'header.document': '文档',
    'graphnext.interrupt': '收集中断',
    'graphnext.undefined': '未定义',
    'menu.projects.id.favorites': '收藏夹',
    'menu.projects.id.favorites.faqs': '用户问答(FAQ)',
    'menu.projects.id.favorites.flows': '对话流图',
    purchase: '充值',
  },
};

export default i18n;
