const i18n = {
  'en-US': {
    'ready.proccess': 'The first login is initializing the resource. This process may last for several minutes. After the resource initialization, the waiting time can be reduced. This process is optional. After skipping, the system will execute this process when necessary...',
    'continue.back': 'Continue'
  },
  'zh-CN': {
    'ready.proccess': '首次登录正在初始化资源，这个过程可能持续几分钟，资源初始化后可减少使用等待时间。这个过程是可选的，跳过后系统将在必要时执行该过程...',
    'continue.back': '跳过'
  },
};

export default i18n;
