const i18n = {
  'en-US': {
    'login.nav.docs': 'Documentation',
    'login.nav.examples': 'Examples',
    'login.nav.aboutUs': 'About Us',
    'login.form.tabs.sms': 'CODE',
    'login.form.tabs.pwd': 'PASSWORD',
    'login.form.title': 'One Click Away From Prompt AI',
    'login.form.userName.errMsg': 'Account cannot be empty',
    'login.form.password.errMsg': 'Password cannot be empty',
    'login.form.code.errMsg': 'Code cannot be empty',
    'login.form.login.errMsg': 'Login error, refresh and try again',
    'login.form.userName.placeholder': 'Email',
    'login.form.userName.mobile.placeholder': 'Email',
    'login.form.password.placeholder': 'Password',
    'login.form.rememberPassword': 'Remember password',
    'login.form.forgetPassword': 'Forgot password',
    'login.form.code.placeholder': 'Code',
    'login.form.login': 'Sign in',
    'login.form.register': 'New to Prompt AI ?',
    'login.success': 'Login succeeded',
    'entry.scan': 'Scan to join Wechat',
    'login.form.register.createAccount': 'Create an account.',
    'login.form.or': 'OR',
    'google.label': 'Sign in with Google',
    'login.form.forgot': 'I forgot my password',
  },
  'zh-CN': {
    'login.nav.docs': '文档',
    'login.nav.examples': '示例',
    'login.nav.aboutUs': '关于我们',
    'login.form.tabs.sms': '验证码',
    'login.form.tabs.pwd': '密码',
    'login.form.title': 'One Click Away From Prompt AI',
    'login.form.userName.errMsg': '帐号不能为空',
    'login.form.password.errMsg': '密码不能为空',
    'login.form.code.errMsg': '验证码不能为空',
    'login.form.login.errMsg': '登录出错，轻刷新重试',
    'login.form.userName.placeholder': '电话号码 / 电子邮件',
    'login.form.userName.mobile.placeholder': '电话号码 / 电子邮件',
    'login.form.password.placeholder': '密码',
    'login.form.rememberPassword': '记住密码',
    'login.form.forgetPassword': '忘记密码',
    'login.form.code.placeholder': '验证码',
    'login.form.login': '登录',
    'login.form.register': '没有账号?',
    'login.success': '登录成功!',
    'entry.scan': '扫码加入微信',
    'login.form.register.createAccount': '创建账号',
    'login.form.or': '或者',
    'google.label': '谷歌登录',
    'login.form.forgot': '忘记密码',
  },
};

export default i18n;
