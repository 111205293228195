const i18n = {
  'en-US': {
    'dynamic.delete': 'Delete',
    'dynamic.delete.tip.prefix': 'Are you sure you want to delete the flow "',
    'dynamic.delete.tip.suffix': '"?',
  },
  'zh-CN': {
    'dynamic.delete': '删除',
    'dynamic.delete.tip.prefix': '你确定想删除该流图（',
    'dynamic.delete.tip.suffix': '）吗?',
  },
};
export default i18n;
