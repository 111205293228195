
const imgs_zh = [
  { src: '/introduce/img/zh/dashboard.png', title: '我们是一群RASA 对话开源系统的爱好者。' },
  { src: '/introduce/img/zh/faq.png', title: '使用RASA实现在金融,车企和政务各种场景的对话机器人,得益于RASA的开放架构,使得我们的系统能够轻易超越同类闭源系统。' },
  { src: '/introduce/img/zh/form.png', title: '鉴于RASA工程化经验,我们去繁化简,贡献社区,全球独家发布基于RASA的脑图无代码编程工具,无论您是否熟悉RASA,都可极简实现对话系统,在几分钟内完成一个对话设计,并上线。' },
  { src: '/introduce/img/zh/project-main.png', title: '您也可免费下载生成的RASA文件,私有部署。' },
  { src: '/introduce/img/zh/publish.png', title: '我们将适时推出 基于GPT3(ChatGPT)的自然语言服务，进一步简化设计，提高对话品质。' },
];
const imgs_en = [
  { src: '/introduce/img/en/dashboard.png', title: 'We are a group of fans of RASA dialogue with open source systems.' },
  { src: '/introduce/img/en/faq.png', title: 'Using RASA to realize dialogue robots in various scenarios of finance, automobile enterprises and government affairs, thanks to the open architecture of RASA, our system can easily surpass similar closed source systems.' },
  { src: '/introduce/img/en/form.png', title: 'In view of the engineering experience of RASA, we will simplify and contribute to the community. We will release the brain map codeless programming tool based on RASA exclusively in the world. Whether you are familiar with RASA or not, you can realize the dialogue system in a very simple way, complete a dialogue design in a few minutes, and go online.' },
  { src: '/introduce/img/en/project-main.png', title: 'You can also download the generated RASA file for private deployment for free.' },
  { src: '/introduce/img/en/publish.png', title: 'We will launch the natural language service based on GPT3 (ChatGPT) in due time to further simplify the design and improve the quality of dialogue.' },
];


const config = (() => ({
  image: {
    zh: imgs_zh.map(({ src, title }) => ({ key: src.split('.')[0], src, title })),
    en: imgs_en.map(({ src, title }) => ({ key: src.split('.')[0], src, title })),
  }
}))();
export default config;