import React from 'react';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import modules from './LoginComponent.module.less';
import useLocale from '@/utils/useLocale';
import i18n from '../locale';

const outh_google_client_id =
  '1014029540131-79opd8dfs2rbqjerhum4k3ot2ugqm2a9.apps.googleusercontent.com';
const LoginComponent = () => {
  const t = useLocale(i18n);
  const url = useMemo(
    () =>
      window.location.protocol +
      '//' +
      window.location.host +
      '/api/oauth/google',
    []
  );
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <div
        className={classNames(
          modules.googleAuthContainer,
          'arco-btn arco-btn-outline arco-btn-size-default arco-btn-shape-square arco-btn-long'
        )}
      >
        <div className={classNames(modules.originalContainer)}>
          <div
            id="g_id_onload"
            className={modules.googleButton}
            data-client_id={outh_google_client_id}
            data-login_uri={url}
            data-auto_prompt="false"
          />
          <div
            className={classNames('g_id_signin', modules.googleButton)}
            data-type="standard"
            data-size="large"
            data-width="100%"
            data-theme="outline"
            data-text="sign_in_with"
            data-shape="rectangular"
            data-logo_alignment="center"
          />
        </div>
        <div className={modules.buttonContainer}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            className={modules.buttonIcon}
          >
            <g>
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </g>
          </svg>
          <span>{t['google.label']}</span>
        </div>
      </div>
    </>
  );
};

export default LoginComponent;
