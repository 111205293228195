const i18n = {
  'en-US': {
    'register.form.title': 'Apply for a new account',
    'register.form.sub': 'PROFILE',
    'register.form.userName.errMsg': 'Username cannot be empty',
    'register.form.password.errMsg': 'Password cannot be empty',
    'register.form.code.errMsg': 'Code cannot be empty',
    'register.form.register.errMsg': 'Login error, refresh and try again',
    'register.form.sourceMsg': 'login was successful',
    'register.form.userName.placeholder': 'Username',
    'register.form.registryCode.placeholder': 'Registry Code',
    'register.form.registryCode.link': 'Applying For',
    'register.form.password.placeholder': 'Password',
    'register.form.phone.placeholder': 'Phone',
    'register.form.phone.errMsg':
      'Please fill in the correct mobile phone number in Chinese Mainland!',
    'register.form.phone.and.email.errMsg':
      'Please fill in the correct mobile phone number or email!',
    'register.form.email.placeholder': 'Email',
    'register.form.email.errMsg': 'Please fill in the correct email address',
    'register.form.email.to.phone': 'Use Mobile',
    'register.form.phone.to.email': 'Use Email',
    'register.form.forgetPassword': 'Forgot password',
    'register.form.code.placeholder': 'Please',
    'register.banner.slogan1': 'Out-of-the-box high-quality template',
    'register.banner.subSlogan1':
      'Rich page templates, covering most typical business scenarios',
    'register.banner.slogan2': 'Built-in solutions to common problems',
    'register.banner.subSlogan2':
      'Internationalization, routing configuration, state management everything',
    'register.banner.slogan3': 'Access visualization enhancement tool AUX',
    'register.banner.subSlogan3': 'Realize flexible block development',
    'register.signIn': 'Sign in',
    'register.signIn.placeholder': 'Already have an account?',
    'register.submit': 'Register',
    'register.sendCode': 'SEND CODE',
    'register.resendCode': 'RESEND AFTER',
    'register.form.company.placeholder': 'Company(option)',
    'register.form.description.placeholder': 'Description(option)',
    'register.form.success': 'Login Was Successful',
    'register.message':
      'Your verification password is your initial password. We suggest you change your password immediately',
    'register.form.submit.notification.title': 'Notification',
    'register.form.submit.notification.content':
      'Please check your email or SMS',
  },
  'zh-CN': {
    'register.message': '您的验证码就是您的初始密码，我们建议你立即修改密码',
    'register.form.title': '申请新帐号',
    'register.form.sub': '帐号信息',
    'register.form.userName.errMsg': '用户名不能为空',
    'register.form.password.errMsg': '密码不能为空',
    'register.form.code.errMsg': '验证码不能为空',
    'register.form.sourceMsg': '注册成功',
    'register.form.errMsg': '注册出错，轻刷新重试',
    'register.form.userName.placeholder': '用户名',
    'register.form.registryCode.placeholder': '注册码',
    'register.form.registryCode.link': '申请注册码',
    'register.form.password.placeholder': '密码',
    'register.form.phone.placeholder': '系统登录手机号',
    'register.form.phone.errMsg': '请填写正确的中国大陆地区手机号!',
    'register.form.phone.and.email.errMsg': '请填写正确的手机号或邮件!',
    'register.form.email.placeholder': '系统登录邮箱',
    'register.form.email.errMsg': '请填写正确的电子邮箱',
    'register.form.email.to.phone': '使用电话号码',
    'register.form.phone.to.email': '使用邮箱',
    'register.form.rememberPassword': '记住密码',
    'register.form.forgetPassword': '忘记密码',
    'register.form.code.placeholder': '验证码',
    'register.banner.slogan1': '开箱即用的高质量模板',
    'register.banner.subSlogan1': '丰富的的页面模板，覆盖大多数典型业务场景',
    'register.banner.slogan2': '内置了常见问题的解决方案',
    'register.banner.subSlogan2': '国际化，路由配置，状态管理应有尽有',
    'register.banner.slogan3': '接入可视化增强工具AUX',
    'register.banner.subSlogan3': '实现灵活的区块式开发',
    'register.signIn': '登录',
    'register.signIn.placeholder': '已经有一个账户？',
    'register.submit': '申请注册',
    'register.sendCode': '发送验证码',
    'register.resendCode': '重新发送',
    'register.form.company.placeholder': '公司(可选)',
    'register.form.description.placeholder': '请描述一下您的使用场景(可选)',
    'register.form.success': '注册成功',
    'register.form.submit.notification.title': '注意',
    'register.form.submit.notification.content': '请注意查收你的邮件或者短信',
  },
};

export default i18n;
